import { type MetaFunction } from '@remix-run/cloudflare';

import Cta from '~/components/Cta';
import Faqs from '~/components/Faqs';
import { HeadingWithSlideShow } from '~/components/HeadingWithSlideShow';
import Pricing from '~/components/Pricing';
import Services from '~/components/Services';
import Stats from '~/components/Stats';

export const meta: MetaFunction = () => {
  return [
    { title: 'Arabesque - Portfolio Dragon' },
    {
      name: 'description',
      content:
        'Create, augment and analyse your portfolio at scale and harness the power of machine learning with our cutting-edge AI.',
    },
    {
      name: 'keywords',
      content: 'Arabesque, Portfolio Dragon, AI, machine learning, portfolio management',
    },
    {
      name: 'og:title',
      content: 'Arabesque - Portfolio Dragon',
    },
    {
      name: 'og:description',
      content:
        'Create, augment and analyse your portfolio at scale and harness the power of machine learning with our cutting-edge AI.',
    },
    {
      name: 'og:image',
      content: 'https://arabesque.ai/favicon.ico', // TODO: Update with a proper image
    },
    {
      name: 'og:url',
      content: 'https://arabesque.ai/',
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'og:type',
      content: 'website',
    },
  ];
};

export default function Index() {
  return (
    <>
      <HeadingWithSlideShow />
      <Stats />
      <Services />
      <Pricing />
      <Cta />
      <Faqs />
    </>
  );
}
