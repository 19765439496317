import { ButtonNew } from '@aether/ui/ButtonNew';
import { getAppOrigin } from '@aether/utils/services/linking-service';
import { Link, useRouteLoaderData } from '@remix-run/react';

import { type loader } from '~/root';

const packages = [
  {
    name: 'Free',
    description: 'For companies that want to get started with portfolio management',
    price: '$0',
    features: [
      '30 days access',
      'Analyze portfolio',
      'Holdings upload',
      'Rebalance holdings',
      'Export holdings',
      'Build portfolios',
    ],
  },
  {
    name: 'Premium',
    description: 'For companies that want to take their portfolio management to the next level',
    price: '$25k',
    features: [
      'All Equity portfolios',
      'Holdings upload',
      'Rebalance holdings',
      'Export holdings',
      'Build portfolios',
      'Compare portfolios',
      'Backtesting',
      'Data management',
    ],
  },
  {
    name: 'API',
    description: 'A plan for companies that want to integrate our platform into their own systems',
    price: '$50k',
    features: [
      'All Equity portfolios',
      'Holdings upload',
      'Rebalance holdings',
      'Export holdings',
      'Build portfolios',
      'Compare portfolios',
      'Backtesting',
      'Data management',
      'Python API SDK',
    ],
  },
  {
    name: 'Implementation Partner',
    description: 'Dedicated support and infrastructure for your company',
    price: '$175k',
    features: [
      'All Equity portfolios',
      'Holdings upload',
      'Rebalance holdings',
      'Export holdings',
      'Build portfolios',
      'Compare portfolios',
      'Backtesting',
      'Data management',
      'Analytics',
      'Attribution',
      'Custom Factsheets',
    ],
  },
];

type PricingPackage = {
  name: string;
  description: string;
  price: string;
  features: string[];
};

export default function Pricing() {
  const data = useRouteLoaderData<typeof loader>('root');
  const host = getAppOrigin(data?.origin) || undefined;
  return (
    <div className="mb-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Our web-based platform is free to use, with premium features available for purchase.
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Leverage the power of AI and big data via our cloud-native no-code solution.
        </p>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {packages.map(pkg => {
            return <PricingPackage pkg={pkg} host={host} />;
          })}
        </div>
      </div>
    </div>
  );
}

const PricingPackage = ({ pkg, host }: { pkg: PricingPackage; host?: string }) => {
  return (
    <div className="rounded-3xl p-8 ring-1 ring-gray-200 hover:ring-2 hover:ring-portfolio-600">
      <h3 id="tier-hobby" className="text-lg font-semibold leading-8 text-gray-900">
        {pkg.name}
      </h3>
      <p className="mt-4 h-20 text-sm leading-6 text-gray-600">{pkg.description}</p>
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-4xl font-bold tracking-tight text-gray-900">{pkg.price}</span>
        <span className="text-sm font-semibold leading-6 text-gray-600">Billed annually</span>
      </p>

      <ButtonNew className="mt-6 w-full font-semibold text-portfolio-600 ring-portfolio-300 " variant="secondary">
        <Link to={`${host}/auth/register`}>Get started today</Link>
      </ButtonNew>
      <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
        {pkg.features.map(feature => {
          return (
            <li className="flex gap-x-3">
              <svg
                className="h-6 w-5 flex-none text-indigo-600"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clipRule="evenodd"
                />
              </svg>
              {feature}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
