import { useState } from 'react';

const faqItems = [
  {
    question: 'What is sustainability data i.e. ESG, GC and Temperature scores used by Portfolio Dragon?',
    answer: `<p>
      Sustainability data used in Portfolio Dragon system are provided by ESG Book, these data include:
      ESG, GC, Temperature scores and Preference Filters. We provide some descriptions for each of the
      aforementioned data in the following sections.
    </p>
    <p>
      <a href="https://www.esgbook.com/" target="_blank" rel="noreferrer">
        Refer to ESG Book Website for further details.
      </a>
    </p>
    <ul>
      <li>
        <p><b>The ESG Score</b>, ranging from 0 to 100, identifies sustainable companies that are better positioned
        to outperform over the long run, based on the principles of financial materiality. That is, when
        computing the ESG Score of a company, the algorithm will only use information that significantly helps
        explain future risk-adjusted performance. Materiality is applied by overweighting features with higher
        materiality and rebalancing these weights on a rolling quarterly basis to stay up-to-date. The ESG
        consists of three sub-scores:</p>
        <ul>
          <li>
            Environment: Emissions, Environmental Stewardship, Resource Use, Environmental Solutions, Waste,
            Water, Environmental Management
          </li>
          <li>
            Social: Diversity, Occupational Health and Safety, Training and Development, Product Access,
            Community Relations, Product Quality and Safety, Human Rights, Labour Rights, Compensation,
            Employment Quality
          </li>
          <li>
            Governance: Business Ethics, Corporate Governance, Transparency, Forensic Accounting, Capital
            Structure
          </li>
        </ul>
      </li>
      <li>
        <p><b>The GC Score</b>, ranges from 0 to 100, is a normative assessment of each company based on the four
        core principles of the United Nations Global Compact - UNGC: Human Rights, Labour Rights, Environment
        and Anti-Corruption. With more than 9,000 corporate signatories from over 160 countries, the Global
        Compact is the world’s largest corporate sustainability initiative, calling on companies and
        stakeholders to conduct business responsibly, and to pursue opportunities that advance Sustainable
        Development Goals (SDGs). Each core principle focuses on the following issues:</p>
        <ul>
          <li>
            <b>Human Rights</b>: Labour Rights, Occupational Health and Safety, Employment Quality, Diversity,
            Product Quality and Safety, Product Access, Community Relations
          </li>
          <li>
            <b>Labour Rights</b>: Diversity, Compensation, Occupational Health and Safety, Training and
            Development, Labour Rights Employment Quality
          </li>
          <li>
            <b>Environment</b>: Emissions, Waste, Environmental Stewardship, Environmental Management, Resource
            Use, Environment Water, Environmental Solutions.
          </li>
          <li>
            <b>Anti-Corruption</b>: Anti-Corruption Business Ethics, Corporate Governance, Transparency
          </li>
        </ul>
      </li>
      <li>
        <p><b>Preferences Filter</b>: is a bespoke search tool that examines the business involvements of companies
        against their personal values. The tool allows the user to look into whether companies are engaging in
        certain activities, enabling the user to avoid those that do not align with personal preferences. The
        Preferences Filter examines revenue streams, and will flag a company if it derives significant revenues
        from a particular activity. There are two kinds of filter:</p>
        <ul>
          <li>
            <p><b>Standard Filter</b>:</p>
            <ul>
              <li>
                Adult Entertainment: Production and distribution of adult content online or in television, video
                or magazines.
              </li>
              <li>Alcohol: Manufacture, production and wholesale of alcoholic beverages.</li>
              <li>
                Thermal Coal: Mining and processing of thermal coal, or the leasing of thermal coal mines.
              </li>
              <li>
                Weapons Involvement: Involvement in the manufacturing and supply-chain of weapons, in the
                manufacturing and supply-chain of landmines, cluster bombs, chemical and biological weapons, or
                nuclear.
              </li>
              <li>
                Defence: Advanced combat equipment, services and technology designed for the defence industry,
                including communications, electronics and software designed for defence applications.
              </li>
              <li>Drug: Cultivation of cannabis and production of cannabis products.</li>
              <li>Firearm: Weaponry, small firearms, and ammunition.</li>
              <li>
                Fossil_fuel: Oil, gas and coal exploration, production and distribution. Oil and gas equipment
                and services. Provision of electricity generated from fossil fuels.
              </li>
              <li>
                Gambling: Casinos, casino hotels, gambling equipment and software, and horse racing industries.
              </li>
              <li>GMO: Plant and animal breeding, genetics and genomics.</li>
              <li>
                Nuclear: Mining of Uranium/Radium/Vanadium, processing nuclear waste, provision of electricity
                generated through nuclear power.
              </li>
              <li>Stem Cell: Genomic screening and cloning, collecting and storing stem cells.</li>
              <li>Tobacco: Manufacture, production and wholesale of tobacco and tobacco products.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <b>Temperature scores</b>: quantifies the extent to which corporations are contributing to the rise in
        global temperature through their greenhouse gas emissions. The score is inspired by the Paris Agreement
        which saw 197 countries agree that global temperature rise needs to be kept “well below 2C” and to
        “pursue efforts to limit the temperature increase to 1.5°C”. Companies are given a near-term (by the
        year 2030) and long-term (by the year 2050) score among 1.5°C, 2°C, 2.7°C, &gt;2.7°C to represent their
        climate pathways. 3°C This score is given to companies that have incomplete emissions reporting.
      </li>
    </ul>`,
  },
  {
    question: 'How does portfolio configuration using ESG sustainability data work?',
    answer: `<p>
    Using <b>ESG </b> and <b>GC </b> scores, the investment universe can be configured according to a
    sustainability target by removing a percentage of companies with the <b>lowest </b>
    sustainability score from the investible universe.
  </p>
  <p>
    For ESG, there are four filters which target the ESG total score and its subscores: Environment, Social
    and Governance.
  </p>
  <p>
    For UNGC, there are five filters which target the total UNGC score and its four subscores: Human Rights,
    Labour Rights, Environment and Anti-Corruption.
  </p>
  <p>
    The filters are used in conjunction. For example, a selection of 40% for ESG total score, 10% for E and
    20% for S will construct an universe consists of assets which simultaneous belong in the top 60%, 90% and
    80% ranking for ESG, E and S score respectively.
  </p>`,
  },
  {
    question: 'How does portfolio configuration using Temperature Score work?',
    answer: `<p>Temperature score filters provide four options</p>
    <ul>
      <li>
      <b>1.5°C:</b> exclude all stocks whose activities will lead to greater than 1.5°C: warming by 2050 according
        to ESG Book
      </li>
      <li>
      <b>2°C:</b> exclude all stocks whose activities will lead to greater than 2.°C: warming by 2050 according to
        ESG Book
      </li>
      <li>
      <b>2.7°C:</b> exclude all stocks whose activities will lead to greater than 2.7°C: warming by 2050 according
        to ESG Book
      </li>
      <li><b>None:</b> no exclusion based on projected contribution to warming.</li>
    </ul>`,
  },
  {
    question: 'How does portfolio configuration using Preference Filters work?',
    answer: `<p>
    Standard Preference Filters. Each filter allows the exclusion of stocks according to their involvement
    with certain business segments. The filters are enumerated below:
  </p>
  <ul>
    <li>
    <b>Adult Entertainment:</b> Production and distribution of adult content online or in television, video or
      magazines.
    </li>
    <li><b>Alcohol:</b> Manufacture, production and wholesale of alcoholic beverages.</li>
    <li>
    <b>Defence:</b> Advanced combat equipment, services and technology designed for the defence industry, including
      communications, electronics and software designed for defence applications.
    </li>
    <li><b>Drugs:</b> Cultivation of cannabis and production of cannabis products.</li>
    <li><b>Firearms:</b> Weaponry, small firearms, and ammunition.</li>
    <li>
    <b>Fossil Fuels:</b> Oil, gas and coal exploration, production and distribution. Oil and gas equipment and
      services. Provision of electricity generated from fossil fuels.
    </li>
    <li><b>Gambling:</b> Casinos, casino hotels, gambling equipment and software, and horse racing industries.</li>
    <li><b>GMO:</b> Plant and animal breeding, genetics and genomics.</li>
    <li>
    <b>Nuclear:</b> Mining of Uranium/Radium/Vanadium, processing nuclear waste, provision of electricity generated
      through nuclear power.
    </li>
    <li><b>Thermal Coal:</b> Mining and processing of thermal coal, or the leasing of thermal coal mines.</li>
    <li><b>Tobacco:</b> Manufacture, production and wholesale of tobacco and tobacco products.</li>
    <li><b>Weapons Involvement:</b> Involvement in the manufacturing and supply-chain of weapons</li>
    <li>
    <b>Controversial Weapons:</b> Involvement in the manufacturing and supply-chain of landmines, cluster bombs,
      chemical and biological weapons, or nuclear.
    </li>
    <li><b>Stem Cells:</b> Genomic screening and cloning, collecting and storing stem cells.</li>
  </ul>`,
  },
  {
    question: 'How does portfolio configuration using Conviction Style work?',
    answer: `<p>
    Conviction Style allow users to limit the total amount of money invested into a single asset (the position
    size) to a certain threshold. There are three available options:
  </p>
  <ol>
    <li>
    <b>Concentrated</b>
      <ul>
        <li>The maximum absolute position size is 5% of the portfolio NAV.</li>
      </ul>
    </li>
    <li>
    <b>Diversified</b>
      <ul>
        <li>The maximum absolute position size is 1% of the portfolio NAV.</li>
      </ul>
    </li>
    <li>
    <b>Moderate</b>
      <ul>
        <li>The maximum absolute position size is 2.5% of the portfolio NAV.</li>
      </ul>
    </li>
  </ol>`,
  },
  {
    question: 'How does portfolio configuration using Market Capitalisation work?',
    answer: `<p>
    Market Capitalisation Configuration allows user to construct a strategy's investment universe based on
    market cap. The available options are given below:
  </p>
  <ol>
    <li>
    <b>Any</b>
      <ul>
        <li>The entire investible universe with respect to market capitalisation.</li>
      </ul>
    </li>
    <li>
    <b>Large</b>
      <ul>
        <li>
          Top 15% (developed markets) / 25% (emerging markets) of the investible universe by market
          capitalisation.
        </li>
      </ul>
    </li>
    <li>
    <b>Mid</b>
      <ul>
        <li>
          Stocks with market capitalisation between the 30th-85th percentiles (developed markets) and
          50th-75th percentiles (emerging markets).
        </li>
      </ul>
    </li>
    <li>
    <b>Small</b>
      <ul>
        <li>
          Bottom 30% (developed markets) / 50% (emerging markets) of the investible universe by market
          capitalisation.
        </li>
      </ul>
    </li>
  </ol>`,
  },
  {
    question: 'How does portfolio configuration using Market Exposure work?',
    answer: `<p>Market Exposure Configuration allow user to control a strategy's holdings with the following options:</p>
    <ol>
      <li>
      <b>Long Only</b>
        <ul>
          <li>All stocks in the portfolio have a positive weight.</li>
        </ul>
      </li>
      <li>
      <b>Long/Short</b>
        <ul>
          <li>Stocks in the portfolio can have either a positive or a negative weight.</li>
        </ul>
      </li>
    </ol>`,
  },
  {
    question: 'How does portfolio configuration using Tilt work?',
    answer: `<p>
    Tilt Configuration allow users to configure a strategy's stock selection behaviour with the following
    options:
  </p>
  <ol>
    <li>
    <b>Any</b>
      <ul>
        <li>No tilt towards any particular factor has been specified.</li>
      </ul>
    </li>
    <li>
    <b>Growth</b>
      <ul>
        <li>
          Tilt the portfolio to having exposure to the growth factor, proxied using 1 year earnings growth.
        </li>
      </ul>
    </li>
    <li>
    <b>Low Vol</b>
      <ul>
        <li>
          Tilt the portfolio to having exposure to the low volatility factor, proxied using 1 year daily
          volatility.
        </li>
      </ul>
    </li>
    <li>
    <b>Momentum</b>
      <ul>
        <li>
          Tilt the portfolio to having exposure to the momentum factor, proxied using the difference between
          the 12 month return and 1 month return.
        </li>
      </ul>
    </li>
    <li>
    <b>Value</b>
      <ul>
        <li>
          Tilt the portfolio to having exposure to the value factor, proxied using the price-to-book ratio.
        </li>
      </ul>
    </li>
  </ol>`,
  },
  {
    question: 'How does portfolio configuration using Tilt Implementation work?',
    answer: `<p>
    Tilt Implementation Configuration allows user to control how Tilt behaviour is implemented. Currently,
    there are two methods provided:
  </p>
  <ol>
    <li>
    <b>Constraint</b>
      <ul>
        <li>Constrain the optimization to select a portfolio whose average exposure is 0.5 (Z-score).</li>
      </ul>
    </li>
    <li>
    <b>Filter</b>
      <ul>
        <li>Filter the investible universe according to the exposure of each stock to a factor.</li>
      </ul>
    </li>
  </ol>`,
  },
];

export default function Faqs() {
  const [expandedIndex, setExpandedIndex] = useState<number | null>();

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="mx-auto mb-20 w-full max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {faqItems.map((item, index) => (
            <div className="pt-6" key={index}>
              <dt>
                <button
                  type="button"
                  className="flex w-full items-start justify-between text-left text-gray-900"
                  aria-controls={`faq-${index}`}
                  aria-expanded={expandedIndex === index}
                  onClick={() => toggleExpand(index)}
                >
                  <span className="text-base font-semibold leading-7">{item.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    <svg
                      className={`h-6 w-6 ${expandedIndex === index ? 'hidden' : 'block'}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                    <svg
                      className={`h-6 w-6 ${expandedIndex === index ? 'block' : 'hidden'}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                    </svg>
                  </span>
                </button>
              </dt>
              {expandedIndex === index && (
                <dd className="mt-2 pr-12" id={`faq-${index}`}>
                  <div
                    className="text-gray-600 [&_li]:mb-5 [&_p]:mb-5"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </dd>
              )}
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
