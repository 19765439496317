import { ButtonNew } from '@aether/ui/ButtonNew';
import { getAppOrigin } from '@aether/utils/services/linking-service';
import { Link, useRouteLoaderData } from '@remix-run/react';

import { ImageSlider } from './ImageSlider';
import { OmniSearch } from './Omnisearch';

const images = [
  '/images/fund/overview.png',
  '/images/fund/performance.png',
  '/images/fund/holdings.png',
  '/images/fund/attribution.png',
  '/images/fund/sustainability.png',
  '/images/fund/composition.png',
  '/images/fund/drawdown.png',
  '/images/fund/turnover.png',
];

export function HeadingWithSlideShow() {
  const data = useRouteLoaderData<{ origin: string }>('root');

  return (
    <div className="mx-auto mb-10 w-full max-w-7xl px-6 sm:my-20">
      <div className="flex w-full flex-col md:flex-row md:gap-x-10">
        <div className="mb-10 md:w-2/5">
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:mt-0 sm:text-5xl">
            AI-tools for asset owners & managers
          </h1>
          <p className="my-6 mb-10 text-lg leading-8 text-gray-600">
            Create, augment and analyse your portfolio at scale and harness the predictive power of machine learning.
          </p>
          <p className="mb-2 text-body-large text-gray-600">Get started by entering your identifier or fund name</p>
          <OmniSearch />

          <div className="mt-6 flex gap-x-4">
            <ButtonNew>
              <Link to={`${getAppOrigin(data?.origin)}/strategy/create`}>Build a new portfolio</Link>
            </ButtonNew>

            <ButtonNew variant="function">
              <Link to={`${getAppOrigin(data?.origin)}/strategy/upload-holdings`}>Upload your portfolio</Link>
            </ButtonNew>
          </div>
        </div>
        <div className="flex md:w-3/5">
          <ImageSlider images={images} interval={3000} />
        </div>
      </div>
    </div>
  );
}
