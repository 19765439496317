import React, { useEffect, type PropsWithChildren } from 'react';
import { debounce } from '@aether/utils';

import { ButtonNew } from '../../ButtonNew';
import { Spinner } from '../../Spinner';
import { TextField } from '../../TextField';
import { cn } from '../../utils';

export type Item = {
  id: string;
  name?: string;
  url?: string; // whether or not the item is supported
  parentIdentifier?: string;
};

export type Data = {
  sectionLabel: string;
  loading: boolean;
  items: Item[];
};

export type FundItemType = (
  props: PropsWithChildren<{ data: Item; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }>
) => JSX.Element;

export type OmniSearchProps = {
  data: Data[];
  className?: string;
  onQueryChange?: (query: string) => void;
  fieldGroupClassName?: string;
  fieldClassName?: string;
  iconBeforeClassName?: string;
  hasLabel?: boolean;
  placeholder?: string;
  FundItem?: FundItemType;
};

export const OmniSearch = ({
  className,
  data,
  onQueryChange,
  hasLabel = true,
  fieldGroupClassName,
  fieldClassName,
  iconBeforeClassName,
  placeholder = 'LU0801842559',
  FundItem,
}: OmniSearchProps) => {
  const [query, setQuery] = React.useState<string | undefined>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const setQueryDebounced = debounce((value: string) => setQuery(value), 200);

  useEffect(() => {
    if (query && onQueryChange) {
      setIsOpen(true);
      onQueryChange(query || '');
    }
  }, [query, onQueryChange]);

  return (
    <div
      className={className}
      onKeyDown={e => {
        if (e.key === 'Escape') setIsOpen(false);
      }}
      onBlur={e => {
        const currentTarget = e.currentTarget;
        // setTimeout is used to delay the check until after the blur event is processed
        setTimeout(() => {
          // check if any active sub elements are selected (i.e. by using the keyboard to navigate the dropdown)
          if (!currentTarget.contains(document.activeElement)) setIsOpen(false);
        });
      }}
    >
      <TextField
        onFocus={() => setIsOpen(true)}
        name="isin"
        fieldGroupClassName={cn('h-14', fieldGroupClassName)}
        fieldClassName={cn('text-lg', fieldClassName)}
        iconBeforeClassName={cn('ml-2 text-2xl', iconBeforeClassName)}
        iconBefore="ic:outline-search"
        label={hasLabel && 'Get started by entering your ISIN or fund name:'}
        labelClassName="text-lg mb-3"
        placeholder={placeholder}
        onKeyUp={e => setQueryDebounced((e.target as HTMLInputElement).value)}
      />
      <div className={`relative ${isOpen ? '' : 'hidden'}`}>
        {query && <FundsListElement {...{ data, query, FundItem, setIsOpen }} />}
      </div>
    </div>
  );
};

const FundsListElement = ({
  data,
  query,
  FundItem,
  setIsOpen,
}: {
  data: Data[];
  query: string;
  FundItem?: FundItemType;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // In case the whole list is available locally, we can use it directly without fetching
  const filtered = filterByQuery(data, query);

  return (
    <div className="absolute z-10 max-h-96 w-full overflow-auto rounded border border-border-medium bg-white">
      {filtered.map(data => {
        const totalItems = data.items.length;

        if (data.loading)
          return (
            <FundsListElementHeader
              key={data.sectionLabel}
              section={data.sectionLabel}
              headerChild={
                <>
                  {' '}
                  - Loading… <Spinner className="absolute bottom-2 right-2 h-5" />
                </>
              }
            />
          );

        if (totalItems === 0)
          return (
            <FundsListElementHeader
              key={data.sectionLabel}
              section={data.sectionLabel}
              headerChild={<> - No results found</>}
            />
          );

        // We still paginate due to rendering times, although the number could be higher
        if (totalItems > 100) data.items = paginate(data.items, 0, 100);

        const itemsRemaining = Math.abs(totalItems - data.items.length);

        return (
          <FundsListElementHeader key={data.sectionLabel} section={data.sectionLabel}>
            <FundList data={data.items} FundItem={FundItem} setIsOpen={setIsOpen} />
            {itemsRemaining ? <p className="px-3 py-2">…{itemsRemaining} more results available…</p> : null}
          </FundsListElementHeader>
        );
      })}
    </div>
  );
};

const FundsListElementHeader = ({
  section,
  headerChild,
  children,
}: PropsWithChildren<{ section: string; headerChild?: React.ReactElement }>) => (
  <div key={section} className="relative h-full w-full border-b-2 border-b-slate-200 py-3">
    <p className="px-3 text-left text-xs font-semibold">
      {section}
      {headerChild}
    </p>
    {children}
  </div>
);

const FundList = ({
  data,
  setIsOpen,
  FundItem,
}: {
  data?: Item[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  FundItem?: FundItemType;
}) => {
  if (data && !data.length) {
    return (
      <div className="mb-2 p-2 px-3">
        <p className="p-2 font-semibold">Sorry, we don’t currently support this item at the moment.</p>
        <p className="p-2">Would you like to have it added?</p>
        <div className="p-2 text-right">
          <ButtonNew asChild>
            <a href={`https://www.arabesque.com/ai/contact-us/`}>Contact us</a>
          </ButtonNew>
        </div>
      </div>
    );
  }

  return data?.map(item => <FundItemDefault data={item} key={item.id} FundItem={FundItem} setIsOpen={setIsOpen} />);
};

const FundItemDefault = ({
  data,
  FundItem = props => {
    const { data, ...rest } = props;
    return <a href={data.url} {...rest} />;
  },
  setIsOpen,
}: {
  data: Item;
  FundItem?: FundItemType;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <FundItem data={data} setIsOpen={setIsOpen}>
      <div className="mb-2 w-full p-3 pb-1 text-left hover:bg-blue-50">
        <span className="font-roboto mr-3">{data.name}</span>
        <span className="font-roboto text-gray-400">{data.id}</span>
      </div>
    </FundItem>
  );
};

const filterByQuery = (data: Data[], query?: string | null) => {
  if (!query) return data;
  const lowerCaseQuery = query.toLocaleLowerCase();
  const searchArray = lowerCaseQuery.split(' ');

  return data.map(data => ({
    loading: data.loading,
    sectionLabel: data.sectionLabel,
    items: data.items.filter(item =>
      searchArray.every(word => item.id.toLowerCase().includes(word) || item.name?.toLowerCase().includes(word))
    ),
  }));
};

const paginate = <T extends any[]>(data: T, offset = 0, limit?: number) =>
  !limit ? data : data.slice(offset, offset + limit);
