import { cn } from '@aether/ui';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { getAppOrigin } from '@aether/utils/services/linking-service';
import { Link, useRouteLoaderData } from '@remix-run/react';

import { type loader } from '~/root';

export default function Cta({
  fontColorClass = 'text-white',
  bgColorClass = 'bg-gray-700',
}: {
  fontColorClass?: string;
  bgColorClass?: string;
}) {
  const data = useRouteLoaderData<typeof loader>('root');
  const host = getAppOrigin(data?.origin);
  return (
    <div className={cn('relative isolate mb-20 overflow-hidden bg-gray-700', bgColorClass)}>
      <div className="px-6 py-24 sm:px-6 sm:py-20 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className={cn('text-3xl font-bold tracking-tight  sm:text-4xl', fontColorClass)}>
            Addressing industry pain points
          </h2>
          <p className={cn('mx-auto mt-6 max-w-xl text-lg leading-8 ', fontColorClass)}>
            Enhance performance by integrating a proprietary AI alpha signal covering 25,000 listed stocks daily.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <ButtonNew asChild className="hover:bg-portfolio-400">
              <Link to={`${host}/auth/register`}>Create Account</Link>
            </ButtonNew>

            <ButtonNew asChild className={cn(' hover:bg-white hover:text-gray-700', fontColorClass)} variant="function">
              <Link to={`${host}/auth/login`}>
                Sign In <span aria-hidden="true">→</span>
              </Link>
            </ButtonNew>
          </div>
        </div>
      </div>
    </div>
  );
}
