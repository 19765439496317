/**
 * Throttles a function to be run only once per browser animation frame (16ms).
 *
 * example:
 *  function myScrollHandler(){ ...some expensive stuff...}
 *  const throttledScrollHandler = rafThrottle(myScrollHandler)
 *  window.document.onScroll(e => throttledScollHandler(e)) // will be called only once per browser frame
 */
export function rafThrottle<T extends (...arg: any) => void>(callback: T): T {
  let requestId: number | null = null;
  let lastArgs: Parameters<T>;

  const later = (context: ThisType<any>) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  return function (this: ThisType<any>, ...args: Parameters<T>) {
    lastArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  } as T;
}

/**
 * Throttles a function to be run only once per per given interval (in ms).
 *
 * example:
 *  function myScrollHandler(){ ...some expensive stuff...}
 *  const throttledScrollHandler = throttle(myScrollHandler, 300)
 *  window.document.onScroll(e => throttledScollHandler(e)) // will be called only once per 300ms interval;
 */
export function throttle<T extends (...args: any[]) => any>(func: T, limit: number): T {
  let timeout: NodeJS.Timeout;
  let lastRan: number;

  return function (this: ThisType<any>, ...args: any[]) {
    const currentTime = Date.now();
    if (!lastRan) {
      func.apply(this, args);
      lastRan = currentTime;
    } else {
      clearTimeout(timeout);
      if (currentTime - lastRan >= limit) {
        func.apply(this, args);
        lastRan = currentTime;
      } else {
        timeout = setTimeout(
          () => {
            func.apply(this, args);
            lastRan = Date.now();
          },
          limit - (currentTime - lastRan)
        );
      }
    }
  } as T;
}

/**
 * Debounces a function to be run only once after a given delay (in ms)
 *
 * example:
 *  function fetchSearchResults(text){ ...apiCall...}
 *  const debouncedFetch = debounce(fetchSearchResults, 200);
 *  <input type="search" onChange={(e) => debouncedFetch(e.target.value)} /> // executes the api call only when the user is not typing for 200ms
 */
export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): T {
  let timeoutId: NodeJS.Timeout;
  return function (this: ThisType<any>, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as T;
}
