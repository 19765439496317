import { Link } from '@remix-run/react';

export default function Stats() {
  return (
    <div className="mx-auto max-w-7xl px-6 sm:pb-20 ">
      <dl className="mx-auto mb-20 mt-5 grid grid-cols-2 gap-px bg-gray-900/5 ring-1 ring-gray-900/5 drop-shadow-md sm:mb-0 md:grid-cols-3 lg:grid-cols-6">
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-base font-medium leading-6 text-gray-500">High performance computation</dt>
          <dd className="leading-0 mt-auto w-full flex-none text-sm font-medium tracking-tight text-gray-900">
            <Link to="https://cloud.google.com/customers/arabesque-ai" className="hover:tex-gray-900 flex items-center">
              Learn more
              <img src="/images/google.png" alt="google cloud" className=" w-20" />
            </Link>
          </dd>
        </div>

        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-base font-medium leading-6 text-gray-500">Trained Machine Learning Models</dt>
          <dd className="mt-auto w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            7,722
          </dd>
        </div>

        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-base font-medium leading-6 text-gray-500">Daily AI Signals</dt>
          <dd className="mt-auto w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            95,000
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-base font-medium leading-6 text-gray-500">Data points</dt>
          <dd className="mt-auto w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            22.44B
          </dd>
        </div>

        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-base font-medium leading-6 text-gray-500">API Endpoints</dt>
          <dd className="mt-auto w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            300+
          </dd>
        </div>

        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-base font-medium leading-6 text-gray-500">Stocks predictions</dt>
          <dd className="mt-auto w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            25,000+
          </dd>
        </div>
      </dl>
    </div>
  );
}
